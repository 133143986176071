<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #FF0000">แก้ไขยี่ห้อสินค้า</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อยี่ห้อสินค้า</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="brandName"
              label="ชื่อยี่ห้อสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="brandDescription"
              label="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="9" sm="9" xs="9">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปยี่ห้อแบรนด์สินค้า</span>
                  <span style="color: red">*</span>
                  <br />
                  <span style="color: red">ใช้นามสกุล .png ขนาด 300*300 </span>
                  <v-img :src="showImage" width="200px"></v-img>
                  <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /></v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      brandName: "",
      brandDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Branddata = JSON.parse(
      Decode.decode(localStorage.getItem("Branddata"))
    );
    console.log("Branddata", Branddata);
    this.id = Branddata.id;
    this.brandName = Branddata.brandName;
    this.brandDescription = Branddata.description;
    this.activeFlag = Branddata.activeFlag;
    this.showImage = Branddata.brandImg;
    this.pic1 = Branddata.brandImg;
    this.picture_1 = Branddata.brandImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBrand");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userHealthAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
        };
        const data = {
          id: this.id,
          brandName: this.brandName,
          description: this.brandDescription,
          activeFlag: this.activeFlag,
          brandImg: this.pic1,
          sortIndex: 100,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/brands/` + this.id,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `อัพเดตยี่ห้อสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageBrand");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `อัพเดตยี่ห้อสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>